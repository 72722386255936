import React, { useState, useRef, useEffect } from "react"
import tw, { styled, css } from "twin.macro"
import { graphql, useStaticQuery, navigate } from 'gatsby'
import { jsx, keyframes } from '@emotion/react'
import {
	faChevronRight
} from '@fortawesome/free-solid-svg-icons'
import Icon from "../../components/icon"
import mq from '../../styles/media-queries'
import WallpaperBackgroundImage from "../../components/wallpaperBackgroundImage"
import { useTheme } from '../../hooks/useTheme'
import useScript from '../../hooks/useScript'

export const theme = {
	nav: {
		[mq.sm] : {
			"background" : "0",
			"backgroundColor" : "rgba(0, 0, 0, 0.3)"
		},
		a : {
			...tw`text-white! border-white`
		}	
	}
}

const Ad = (props) => {

	console.log('bidglass ad: render')

	const { setTheme } = useTheme()
	const scriptStatus = useScript('https://bid.glass/lib/bg.js')

	useEffect(() => {
		setTheme(theme)
		if (ref.current && scriptStatus === 'ready') {
			console.log('bidglass ad: add ad tag')
			const script = document.createElement('script')
			script.setAttribute('src','https://bid.glass/unit/52.js')
			ref.current.appendChild(script)
		}
	}, [scriptStatus])

	const ref = useRef()

	return (
		<div tw="absolute w-full h-full hidden xl:flex bg-blue pt-14 pr-6 items-center justify-center" css={[
			{
				paddingLeft: "21rem"
			}
		]}>
			<div 
				tw="pointer-events-none" 
				ref={ref} 
				css={{ width: "889px", height: "500px"}}
				className="bidglass-unit-52"
			>
			</div>
		</div>
	)
}

export default Ad
